<template>
  <el-dialog
    v-model="showDialog"
    :title="addrid > 0 ? PAGELANG.编辑地址 : PAGELANG.addbtn"
    width="30%"
    class="edit-account"
    z-index="100"
    @close="closeDialog"
  >
    <el-form
      :model="address"
      label-width="120"
      :rules="rules"
      ref="addressForm"
    >
      <el-form-item :label="PAGELANG.收货人" prop="ReceiverName">
        <el-input
          v-model="address.ReceiverName"
          :placeholder="PAGELANG.请输入收货人姓名"
          autocomplete="off"
          size="large"
          :validate-event="false"
        />
      </el-form-item>
      <el-form-item :label="PAGELANG.手机号码" prop="MobilePhone">
        <el-input
          v-model="address.MobilePhone"
          :placeholder="PAGELANG.请输入手机号"
          autocomplete="off"
          size="large"
          :validate-event="false"
        />
      </el-form-item>
      <el-form-item :label="PAGELANG.国家" prop="CountryID">
        <el-select
          v-model="address.CountryID"
          name="CountryID"
          size="large"
          :validate-event="false"
          :popper-append-to-body="false"
          :placeholder="PAGELANG.选择国家"
          @change="selectCountry"
        >
          <el-option
            v-for="item in countryList"
            :key="item.region_id"
            :label="item.region_name"
            :value="item.region_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="PAGELANG.area" prop="AreaIDs">
        <el-cascader
          v-model="address.AreaIDs"
          :options="addressList"
          :validate-event="false"
          :placeholder="addressLoading ? SYSLANG.loading : PAGELANG.选择省市区"
          :disabled="addressLoading"
          size="large"
          style="width: 100%"
          :props="addressFieldName"
          :popper-append-to-body="false"
          popper-class="pc-sel-area-cascader"
          @change="changeArea"
          ref="refArea"
        />
      </el-form-item>
      <el-form-item :label="PAGELANG.详细地址" prop="AddressDetail">
        <el-input
          v-model="address.AddressDetail"
          :placeholder="PAGELANG.小区楼栋门牌村等"
          autocomplete="off"
          size="large"
          type="textarea"
          rows="4"
          resize="none"
          :validate-event="false"
        />
      </el-form-item>
      <el-form-item :label="PAGELANG.设置为默认地址">
        <el-switch
          v-model="address.IsDefault"
          size="large"
          active-value="1"
          inactive-value="0"
          style="--el-switch-on-color: var(--van-card-price-color)"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false">
          {{ SYSLANG.quxiao }}
        </el-button>
        <el-button type="primary" @click="saveAddress()" color="#000">
          {{ SYSLANG.save }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { showToast, closeToast, showLoadingToast } from "vant";
import { getCurrentInstance } from "vue";
export default {
  name: "AddressEdit",
  data() {
    return {
      showDialog: false,
      addrid: 0,
      address: {},
      rules: {},
      countryList: [],
      addressLoading: false,
      addressList: [],
      addressFieldName: {
        multiple: false,
        emitPath: false,
        value: "region_id",
        label: "region_name",
        children: "children",
        expandTrigger: "click",
        lazy: false,
        lazyLoad: false,
      },
    };
  },
  props: {
    AddrID: {
      type: Number,
      default: -1,
    },
  },
  created() {
    const root = getCurrentInstance().appContext.config.globalProperties;
    this.SYSLANG = root.LANG.system;
    this.PAGELANG = root.LANG.pages.address;
    this.root = root;

    this.rules = {
      ReceiverName: [
        { required: true, message: this.PAGELANG.收货人姓名不能为空 },
      ],
      MobilePhone: [
        { required: true, message: this.PAGELANG.手机号码不能为空 },
      ],
      CountryID: [{ required: true, message: this.PAGELANG.请选择国家 }],
      AreaIDs: [{ required: true, message: this.PAGELANG.请选择所在区域 }],
      AddressDetail: [
        { required: true, message: this.PAGELANG.详细地址不能为空 },
      ],
    };

    this.axios.get(this.actions.address_country).then(({ data }) => {
      let { code, msg, list } = data;
      console.log("address_area", msg);
      if (code == "SUCCESS") {
        this.countryList = list;
      }

      this.countryLoading = false;
    });
  },
  watch: {
    AddrID(newVal, oldVal) {
      console.log("AddrID", newVal, oldVal);
      this.addrid = newVal;
      if (newVal >= 0) {
        this.showDialog = true;
        this.editAddr(newVal);
      }
    },
  },
  methods: {
    closeDialog() {
      console.log("closeDialog", this.addrid);
      this.address = {};
      this.$emit("editAddress", -1);
    },
    changeArea(val) {
      console.log(val);
      this.address.AreaIDs = this.$refs.refArea.getCheckedNodes()[0].pathValues;
    },
    editAddr(addrid = 0) {
      this.addrid = addrid;
      this.showDialog = true;

      if (!isNaN(this.addrid) && this.addrid > 0) {
        this.axios
          .get(this.actions.address_detail + this.addrid)
          .then(({ data }) => {
            let { code, msg, detail } = data;
            console.log(code, msg, detail);
            if (code != "SUCCESS") {
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "NOTLOGIN") {
                    this.$router.replace({
                      path: "/login",
                    });
                  }
                },
              });
            } else if (detail) {
              this.address = detail;
              this.address.CountryID = detail.fkAreaIDL0;

              this.getArea();
            } else {
              this.addrid = 0;
            }
          })
          .catch((error) => {
            console.log("components.AddressEdit.editAddr.error", error);
            closeToast();
            this.editAddr(addrid);
          });
      } else {
        this.addrid = 0;
      }
    },
    getArea() {
      this.address.AreaIDs = [];
      this.addressLoading = true;

      /*showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });*/

      this.axios
        .get(this.actions.address_area + this.address.CountryID)
        .then(({ data }) => {
          closeToast();
          let { code, msg, list } = data;
          console.log("address_area", msg);
          if (code == "SUCCESS") {
            this.addressList = list;
          }

          this.addressLoading = false;
        })
        .catch((error) => {
          console.log("components.AddressEdit.getArea.error", error);
          closeToast();
          this.getArea();
        });
    },
    selectCountry() {
      this.getArea();
    },
    saveAddress() {
      this.$refs.addressForm.validate((valid) => {
        if (valid) {
          this.address.addrid = this.addrid;
          this.address.AreaIDs = JSON.stringify(this.address.AreaIDs);

          showLoadingToast({
            message: this.SYSLANG.正在保存,
            duration: 0,
            forbidClick: true,
          });

          this.axios
            .post(this.actions.address_save, this.address)
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "SUCCESS") {
                    this.showDialog = false;
                    this.$emit("onRefresh");
                  }
                },
              });
            });
        }
      });
    },
  },
};
</script>