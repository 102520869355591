<template>
  <div class="address-page">
    <van-radio-group
      v-model="defaultAddress"
      checked-color="var(--van-card-price-color)"
      icon-size="16"
      @change="setDefault"
      v-if="list.length > 0"
    >
      <ul class="address-list">
        <template v-for="(item, index) in list" :key="index">
          <li class="item">
            <div class="info" @click="selectAddress(index)">
              <span class="area"
                >{{ item.Country }} {{ item.Province }} {{ item.City }}
                {{ item.County }}</span
              >
              <span class="detail">{{ item.AddressDetail }}</span>
              <span class="phone"
                >{{ item.ReceiverName }} {{ item.MobilePhone }}</span
              >
            </div>
            <div class="control">
              <van-radio :name="item.ID">{{
                item.IsDefault == 1 ? PAGELANG.default : PAGELANG.setdefault
              }}</van-radio>
              <div class="btns">
                <van-button
                  type="default"
                  plain
                  size="small"
                  @click="onDelete(item.ID)"
                  >{{ SYSLANG.delete }}</van-button
                >
                <van-button
                  type="default"
                  plain
                  size="small"
                  :to="'/address/detail?addrid=' + item.ID"
                  >{{ SYSLANG.edit }}</van-button
                >
              </div>
            </div>
          </li>
        </template>
      </ul>
    </van-radio-group>

    <van-empty
      v-else
      :image="require('@/assets/images/notdata_address.png')"
      image-size="180"
      :description="SYSLANG.finished"
    >
    </van-empty>

    <div class="add-btn-block">
      <van-button
        type="primary"
        icon="aog1 iconfont icon-add"
        block
        color="var(--van-card-price-color)"
        to="/address/detail"
        >{{ PAGELANG.addbtn }}</van-button
      >
    </div>
  </div>

  <div class="pc-my-page">
    <MyMenu :agentInfo="agentInfo" pagename="address" />
    <div class="main">
      <div
        style="
          display: flex;
          width: 100;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
        "
      >
        <div class="big-title" style="margin-bottom: 0">
          {{ MYLANG.dizhi }}
        </div>
        <el-button color="#000" @click="editAddress()">{{
          PAGELANG.addbtn
        }}</el-button>
      </div>
      <el-table
        :data="list"
        class="logs"
        size="large"
        :empty-text="SYSLANG.finished"
      >
        <el-table-column :label="PAGELANG.area">
          <template #default="scope">
            {{ scope.row.Country }} {{ scope.row.Province }}
            {{ scope.row.City }} {{ scope.row.County }}
          </template>
        </el-table-column>
        <el-table-column prop="AddressDetail" :label="PAGELANG.详细地址" />
        <el-table-column
          prop="ReceiverName"
          :label="PAGELANG.收货人"
          width="120"
        />
        <el-table-column
          prop="MobilePhone"
          :label="PAGELANG.手机号码"
          width="140"
        />
        <el-table-column :label="PAGELANG.default" width="60" align="center">
          <template #default="scope">
            <span
              v-if="scope.row.IsDefault == 1"
              class="iconfont icon-V"
              style="color: green"
            ></span>
            <span
              v-else
              class="iconfont icon-V1"
              style="color: red; cursor: pointer"
              @click="
                defaultAddress = scope.row.ID;
                setDefault(scope.row.ID);
              "
            ></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ID"
          :label="CARTLANG.操作"
          width="160"
          align="right"
        >
          <template #default="scope">
            <el-button type="danger" link @click="onDelete(scope.row.ID)">
              {{ SYSLANG.delete }}
            </el-button>
            <el-button link @click="editAddress(scope.row.ID)">
              {{ SYSLANG.edit }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AddressEdit
      :AddrID="addrid"
      @editAddress="editAddress"
      @onRefresh="init"
    />
  </div>
</template>

<script>
import {
  showToast,
  showConfirmDialog,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";
import MyMenu from "../components/MyMenu.vue";
import AddressEdit from "../components/AddressEdit.vue";

export default {
  components: {
    MyMenu,
    AddressEdit,
  },
  data() {
    return {
      list: [],
      defaultAddress: 1,
      fromorder: 0,
      agentInfo: {},
      addrid: -1,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.address;
    this.GOODSLANG = this.LANG.pages.goods;
    this.CARTLANG = this.LANG.pages.cart;
    this.MYLANG = this.LANG.pages.my;
    this.SYSLANG = this.LANG.system;

    if (this.$route.query.fromorder) {
      this.fromorder = this.$route.query.fromorder;
    }

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.member)
        .then(({ data }) => {
          closeToast();
          let { code, msg, agentInfo } = data;
          if (code == "SUCCESS") {
            this.agentInfo = agentInfo;
            this.init();
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.GOODSLANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          console.log("views.Address.getMemberInfo.error", error);
          closeToast();
          this.getMemberInfo();
        });
    },
    init() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.address)
        .then(({ data }) => {
          closeToast();
          let { code, msg, list } = data;
          if (code == "SUCCESS") {
            for (let i = 0; i < list.length; i++) {
              if (list[i].IsDefault == 1) {
                this.defaultAddress = list[i].ID;
                break;
              }
            }
            this.list = list;
          } else {
            showToast({
              message: this.SYSLANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          console.log("views.Address.init.error", error);
          closeToast();
          this.init();
        });
    },
    setDefault(val) {
      console.log(val);
      // 设置默认地址
      this.axios
        .get(this.actions.address_setdefault + val)
        .then(({ data }) => {
          let { code, msg } = data;
          console.log(code, msg);
          if (code == "SUCCESS") {
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].ID == this.defaultAddress) {
                this.list[i].IsDefault = 1;
              } else {
                this.list[i].IsDefault = 0;
              }
            }
          } else {
            showToast(
              code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg]
            );
          }
        })
        .catch((error) => {
          console.log("views.Address.setDefault.error", error);
          closeToast();
          this.setDefault(val);
        });
    },
    onDelete(addrid) {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.deleteaddrss,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.address_delete + addrid)
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                onClose: () => {
                  if (code == "SUCCESS") {
                    this.init();
                  }
                },
                forbidClick: true,
              });
            })
            .catch((error) => {
              console.log("views.Address.onDelete.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },
    selectAddress(index) {
      if (this.fromorder == 1) {
        this.pagedata.addrinfo = this.list[index];
        this.$router.back();
      }
    },
    editAddress(addrid = 0) {
      console.log(addrid);
      this.addrid = addrid;
    },
  },
};
</script>

<style src="../assets/css/address.css" scoped></style>
<style src="../assets/css/addresslist.css" scoped></style>